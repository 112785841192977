'use strict';

// TODO: remove later
// For debuging purposes

if (window.User) { // eslint-disable-line
    if (!User.isProduction) { // eslint-disable-line
        if (User.customerGroupsInfo) { // eslint-disable-line
            let customerGroupsInfo = JSON.parse(User.customerGroupsInfo); // eslint-disable-line
            console.table(customerGroupsInfo);
        }

        if (User.loyaltyVouchers) {
            let loyaltyVouchers = JSON.parse(User.loyaltyVouchers); // eslint-disable-line
            console.log('Loyalty Vouchers:', loyaltyVouchers);
        }
    }
}

// TODO: remove later
