'use strict';

// TODO: We need to re-initialize select2 widget on PLP on "Load More" Products, applying "Sorting", and applying and resetting "Filters"

const base = require('eminence/product/base');
const { CLASSES } = require('eminence/utils/globals');

const SELECTORS = {
    addToCartPLPButton: '.js-add-to-cart-plp',
    notifyMePLPButton: '.js-notify-me-plp',
    notifyMePLPCTAButton: 'js-notify-me-plp-button',
    notifyMePID: '.js-notify-me-pid-input',
    notifyMeForm: '.js-notifyme-form'
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid = $($el).closest('.product-detail').find('.product-id').text();
    return pid;
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="'
            + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    if ($.isEmptyObject(attr.values) == false) {
        attr.values.forEach(function (attrValue) {
            var $attrValue = $productContainer
                .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
            $attrValue.attr('value', attrValue.url)
                .removeAttr('disabled');

            if (!attrValue.selectable) {
                $attrValue.attr('disabled', true);
            }
        });
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];
    $productContainer.find('.js-add-to-cart-plp').attr('diabled','disabled')
    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
    $productContainer.find('.js-add-to-cart-plp').removeAttr('diabled');
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update Product ID
    var $pidSelector = $productContainer.find('.product-id');
    $pidSelector.text(response.product.id);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $productContainer.find('.price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));

    // Update Short Description
    $productContainer.find('.js-plp-short-description').empty()
        .html(response.product.shortDescription);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.variantURL - Product URL
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function updateProductURL(response, $productContainer) {
    // Update Product ID
    var $productLinkAnchor = $productContainer.find('.js-variant-url');
    $productLinkAnchor.attr('href', response.variantURL);
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $.spinner().start();
        $('body').trigger(
            'product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer }
        );

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                $('body').trigger(
                    'product:afterAttributeSelect',
                    { data: data, container: $productContainer }
                );
                updateProductURL(data, $productContainer);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response, form) {
    $('.minicart').trigger('count:update', response);
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        base.methods.editBonusProducts(response.newBonusDiscountLineItem);
    } else {
        var basketPopUp = require('eminence/components/basketPopup');
        basketPopUp(response, form);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

module.exports = {
    attributeSelect: attributeSelect,
    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-attribute-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },
    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect(
                    $(e.currentTarget).find('option:selected').data('url'),
                    $productContainer
                );
            }
        });
    },
    addToCart: function () {
        $(document).on('click', 'button.js-add-to-cart-plp', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;

            $('body').trigger('product:beforeAddToCart', this);

            pid = $(this).attr('data-pid') || getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: '1'
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data, form);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    getPidValue: getPidValue,
    handleAddToBasketAndNotifyMeButtons: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            // Update the Product ID in the DOM after selecting an variation attribute
            var container = response.container;
            var pid = response.data.product.id;
            container.data('pid', pid);
            container.find('.product-id').text(pid);
            container.find(SELECTORS.addToCartPLPButton).attr('data-pid', pid);
            container.find(SELECTORS.notifyMePLPButton).attr('data-pid', pid);

            // Toggle between "AddToBasket" and "NotifyMe" buttons based on Product availability
            var product = response.data.product;
            if (product.readyToOrder && product.available) {
                container.find(SELECTORS.notifyMePLPButton).addClass(CLASSES.hide);

                var $addToCartPLPButton = $(SELECTORS.addToCartPLPButton, container);
                $addToCartPLPButton.text($addToCartPLPButton.data('addtobasket-instock'));
                if (product.isBackorder) {
                    $addToCartPLPButton.text($addToCartPLPButton.data('addtobasket-backorder'));
                } else if (product.isPreorder) {
                    $addToCartPLPButton.text($addToCartPLPButton.data('addtobasket-preorder'));
                }
                container.find(SELECTORS.addToCartPLPButton).removeAttr('disabled').removeClass(CLASSES.hide);
            } else {
                container.find(SELECTORS.addToCartPLPButton).addClass(CLASSES.hide);
                container.find(SELECTORS.notifyMePLPButton).removeClass(CLASSES.hide);
            }
        });
    },
    updateNotifyProductID: function () {
        $(document).on('click', '.js-notify-me-plp-button', function () {
            $(SELECTORS.notifyMePID).val($(this).attr('data-notify-pid'));
        });
    },
    notifyMeFormSubmit: function () {
        $(SELECTORS.notifyMeForm).on('submit', function (e) {
            e.preventDefault();

            let $form = $(this);
            let url = $form.attr('action');
            $form.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function () {
                    $form.spinner().stop();
                    $('.js-modal-close').trigger('click');
                },
                error: function () {
                    $form.spinner().stop();
                    $('.js-modal-close').trigger('click');
                }
            });
            return false;
        });
    }

};
