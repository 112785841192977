'use strict';

require('select2/dist/js/select2.full.min.js');
require('./select2-dropdownPosition.js');

const { CLASSES } = require('eminence/utils/globals');

const SELECTORS = {
    body: 'body',
    select: '.js-select',
    selectDropdownAbove: '.js-select-dropdown-above',
    selectDropdownBelow: '.js-select-dropdown-below',
    slickSlider: '.js-slick-slider',
};

function resultState(data, container) {
    if (data.element) {
        $(container).addClass($(data.element).attr('class'));
    }
    return data.text;
}

function validateSelectOptions() {
    $(SELECTORS.select).each(function () {
        const $select = $(this);
        const currentValue = $select.val();
        const validOptions = $select.find('option').map((_, option) => $(option).val()).get();

        if (!validOptions.includes(currentValue)) {
            $select.val($select.find('option:first').val());
        }
    });
}

function addPlaceholderIfEmpty() {
    $(SELECTORS.select).each(function () {
        const $select = $(this);
        if ($select.find('option').length === 0) {
            $select.append('<option disabled selected value="">Please select an option</option>');
        }
    });
}

function customSelect() {
    addPlaceholderIfEmpty();
    validateSelectOptions();

    $(SELECTORS.select).each(function () {
        $(this).select2({
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownCssClass: 'custom-select2-dropdown'
        });
    });

    $(SELECTORS.selectDropdownAbove).each(function() {
        if (!$(this).val() || $(this).val() === '') {
            $(this).val($(this).find('option:first').val());
        }

        $(this).select2({
            dropdownPosition: 'above',
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownParent: $(this).parent(),
            dropdownCssClass: 'custom-select2-dropdown'
        });
    });

    $(SELECTORS.selectDropdownBelow).each(function() {
        if (!$(this).val() || $(this).val() === '') {
            $(this).val($(this).find('option:first').val());
        }

        $(this).select2({
            dropdownPosition: 'below',
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownParent: $(this).parent(),
            templateResult: resultState,
            dropdownCssClass: 'custom-select2-dropdown'
        });
    });
}

$(SELECTORS.selectDropdownBelow).each(function() {
    $(this).on('select2:open', handleSelectOpen);
});

$(SELECTORS.selectDropdownBelow).each(function() {
    $(this).on('select2:close', handleSelectClose);
});

function handleSelectOpen() {
    let $slider = $(this).parents(SELECTORS.slickSlider);
    if (!$slider || !$slider.length > 0) {
        return;
    }

    $slider.addClass(CLASSES.selectOpen);
}

function handleSelectClose() {
    let $slider = $(this).parents(SELECTORS.slickSlider);
    if (!$slider || !$slider.length > 0) {
        return;
    }

    $slider.removeClass(CLASSES.selectOpen);
}

const initEvents = () => {
    $(SELECTORS.body).on('select:reInit', () => customSelect());
};

module.exports = function () {
    initEvents();
    customSelect();
};

module.exports.customSelect = customSelect;
