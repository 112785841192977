'use strict';
var createNotification = require('eminence/components/notification');

const SELECTORS = {
    errorMessaging: '.error-messaging'
};

function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

function handleNotification() {
    const message = getQueryParam('message');
    const type = getQueryParam('type');
    if (message !== null && type !== null) {
        createNotification($(SELECTORS.errorMessaging), message, type, 15000);
    }
}

document.addEventListener('DOMContentLoaded', handleNotification);

module.exports = handleNotification;
