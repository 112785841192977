'use strict';

var createNotification = require('eminence/components/notification');
var base = require('eminence/product/base');
var focusHelper = require('base/components/focus');
const clientValidation = require('eminence/components/clientSideValidation');
var location = window.location;
const debounce = require('lodash/debounce');

const { CLASSES } = require('eminence/utils/globals');
const SELECTORS = {
    progressBar: '.js-progress-bar',
    freeShippingProgressBarContainer: '.js-free-shipping-progress-bar-container',
    approachingDiscounts: '.js-approaching-discounts',
    reorderMessage: '.js-reorder-message',
    notificationMessage: '.js-notification-message, .error-messaging',
    oooItemsMessage: '.js-ooo-items-message',
    checkoutBtn: '.js-checkout-btn',
    disabledCheckoutMessage: '.js-disabled-checkout-message',
    editGratisNameLink: '.js-edit-gratis-name',
    cancelEditGratisName: '.js-cancel-gratis-name',
    saveGratisName: '.js-save-gratis-name',
    gratisNameInput: '.js-gratis-name-input',
    gratisNameWrapper: '.js-gratis-name-wrapper',
    gratisNameEditLinkWrapper: '.js-edit-gratis-name-wrapper',
    promoCodeForm: '.js-show-promo-form',
    promoCodeToggle: '.js-promo-code-toggle',
    emailNotifyForm: '#emailNotifyForm',
    optimizeMyRewards: '.js-optimizemyrewards-wrapper',
    giftOrderContainer: '.js-gift-order-container',
    giftOrderMessageCounter: '.js-gift-msg-character-count',
    giftOrderToggleableSection: '.js-gift-order-toggleable-section',
    giftOrderIsGiftOrderField: '#is-gift-order',
    giftOrderRecipientField: '#gift-order-to',
    giftOrderSenderField: '#gift-order-from',
    giftOrderMessageField: '#gift-order-message',
    priorityShipment: '.js-priority-shipment',
    priorityShipmentRes: '.js-priority-shipment-res',
    testerReasonForm: '.tester-reason-form',
    plantTreesContainer: '.js-plant-trees-wrapper-order-summary',
    noOfTrees: '.js-no-of-trees-order-summary',
    treesVerbiage: '.js-trees-verbiage-order-summary',
    qtySelectorContainer: '.js-quantity-selector-container', // All -/+ buttons and input field
    qtyInput: '.js-quantity-input', // quantity input field itself
    qtyButtons: '.js-quantity-count', // -/+ buttons
    minusButton: '.js-quantity-count-minus', // - button
    addButton: '.js-quantity-count-add', // + button
    qtyError: '.js-quantity-error',
}

function updateTreeCounter(basket) {
    var $plantTreesContainer = $(SELECTORS.plantTreesContainer);

    if ($plantTreesContainer.length) {
        var treeCounter = basket.treeCounter;
        $(SELECTORS.noOfTrees).text(parseInt(treeCounter));
        $(SELECTORS.treesVerbiage).text(treeCounter > 1 ? $(SELECTORS.treesVerbiage).attr('data-trees') : $(SELECTORS.treesVerbiage).attr('data-tree'));
    }
}

var GIFT_ORDER_FIELD_SELECTOR_LIST = [
    SELECTORS.giftOrderIsGiftOrderField,
    SELECTORS.giftOrderRecipientField,
    SELECTORS.giftOrderSenderField,
    SELECTORS.giftOrderMessageField
].join(', ');

// Toggles gift fields;
$(SELECTORS.giftOrderIsGiftOrderField).on('change', (function () {
    var isCheckboxChecked = $(this).is(':checked');

    $(SELECTORS.giftOrderToggleableSection).toggleClass('d-none', !isCheckboxChecked);
}));

// Updated the character counter for "Message" field;
$(SELECTORS.giftOrderMessageField).on('keyup', (function () {
    var inputLength = $(this).val().length;

    $(SELECTORS.giftOrderMessageCounter).text(inputLength);
}));

// Disallows non-English characters on all gift fields;
$(GIFT_ORDER_FIELD_SELECTOR_LIST).on('keypress paste', function (e) {
    var regex = new RegExp(/^[a-zA-Z0-9!@#$%^&*\\/|?<,>\.:;{\}\[\]'" ~)(+=._-]+$/g); // eslint-disable-line

    if (!e.key || !regex.test(e.key)) {
       e.preventDefault();
       return false;
    }
});

// Updates "Checkout" button "href" attribute so that the fields inputs are passed as parameters;
// Also, saves gift data to session;
$(GIFT_ORDER_FIELD_SELECTOR_LIST).on('change', (function () {
    var $checkoutBtn      = $(SELECTORS.checkoutBtn);
    var baseUrl           = $checkoutBtn.data('base-url');
    var isGiftOrder       = $(SELECTORS.giftOrderIsGiftOrderField).is(':checked');
    var giftRecipient     = $(SELECTORS.giftOrderRecipientField).val();
    var giftSender        = $(SELECTORS.giftOrderSenderField).val();
    var giftMessage       = $(SELECTORS.giftOrderMessageField).val();
    var newUrl            = baseUrl;
    var updateGiftDataUrl = $(SELECTORS.giftOrderContainer).data('update-url');
    var data              = {
        isGiftOrder,
        giftRecipient,
        giftSender,
        giftMessage
    }

    if (isGiftOrder) {
        newUrl = appendToUrl(baseUrl, data);
    }

    // Updates gift data to session;
    $.ajax({
        url: updateGiftDataUrl,
        type: 'post',
        dataType: 'json',
        data,
    });

    $checkoutBtn.attr('href', newUrl);
}));

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>' + data.resources.emptyCartMsg + '</h1> '
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $(SELECTORS.checkoutBtn).addClass(CLASSES.disabled);
    } else {
        $(SELECTORS.checkoutBtn).removeClass(CLASSES.disabled);
    }
}

/**
 * Checks whether the Checkout is disabled for the
 * Customer based on the profile attribite or Customer Group
 * Checkout minimum
 * @param {Object} data - AJAX response from the server
 */
function verifyCheckoutPermissions(data) {
    if (data.isCheckoutDisabled) {
        $(SELECTORS.checkoutBtn)
            .addClass(CLASSES.disabled)
            .attr(CLASSES.disabled, CLASSES.disabled);
    } else {
        $(SELECTORS.checkoutBtn)
            .removeClass(CLASSES.disabled)
            .removeAttr(CLASSES.disabled);
    }

    let $disabledCheckoutMessage = $(SELECTORS.disabledCheckoutMessage);
    if ($disabledCheckoutMessage && $disabledCheckoutMessage.length) {
        $disabledCheckoutMessage.empty();
        if (data.disabledCheckoutMessage) {
            $disabledCheckoutMessage.append(`
                <span class="alert-info-icon"></span>
                <p class="alert-content">
                    ${data.disabledCheckoutMessage}
                </p>
                <button
                    type="button"
                    class="close
                    close-icon"
                    data-dismiss="alert"
                    aria-label="Close"
                >
                </button>
            `)
                .addClass(CLASSES.show)
                .removeClass(CLASSES.hide);
        } else {
            $disabledCheckoutMessage.addClass(CLASSES.hide);
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts, shippingLevelDiscountTotal) {
    var html = '';
    var orderTotal = '';
    var promotionsTotal = '';
    var shippingDiscount = shippingLevelDiscountTotal;
    $(SELECTORS.approachingDiscounts).empty();
    if (approachingDiscounts.length > 0) {
        $(SELECTORS.freeShippingProgressBarContainer).removeClass(CLASSES.hide).addClass(CLASSES.display);
        approachingDiscounts.forEach(function(item) {
            if (item.promotionID == 'freeShippingWithProgressBar') {
                html += '<div class="b-basket-popup-footer-shipping-message ">' +
                    item.discountMsg + '</div>';
                promotionsTotal = item.thresholdValue;
                orderTotal = item.lineItemTotal;
            }
        });
        var percentAge = (Number(orderTotal.replace(/[C|$]/g, "")) / Number(promotionsTotal.replace(/[C|$]/g, ""))) * 100;
        $(SELECTORS.progressBar).width(percentAge + '%');
    } else if (shippingDiscount == 0) {
        $(SELECTORS.freeShippingProgressBarContainer).removeClass(CLASSES.display).addClass(CLASSES.hide);
    } else {
        $(SELECTORS.progressBar).width('100%');
        html += '<div class="b-basket-popup-footer-shipping-message ">' +
        $(SELECTORS.progressBar).attr('data-free-shipping-availed-msg') + '</div>';
    }

    $(SELECTORS.approachingDiscounts).append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Shows reorder success message
 * @param {void}
 */
function showReorderSuccessMessage() {
    let $reorderMessage = $(SELECTORS.reorderMessage);
    if ($reorderMessage && $reorderMessage.length > 0) {
        createNotification($(SELECTORS.notificationMessage), $reorderMessage.text(), 'success');
        $reorderMessage.remove();
    }
}

/**
 * Shows Out of Stock message
 * @param {void}
 */
function showOutOfStockMessage() {
    let $oooItemsMessage = $(SELECTORS.oooItemsMessage);
    if ($oooItemsMessage && $oooItemsMessage.length > 0) {
        createNotification($(SELECTORS.notificationMessage), $oooItemsMessage.html(), 'info', 5000);
        $oooItemsMessage.remove();
    }
}

function updateSortedB2BProducts(data, uuid) {
    if (data.isCustomerB2B) {
        var sortedCartItems = data.sortedCartItems;

        for(var x = 0; x < sortedCartItems.length; x++) {
            var sortedCartItem = sortedCartItems[x];
            for (var i = 0; i < sortedCartItem.items.length; i++) {
                if (sortedCartItem.totalQty !== 0) {
                    var item = sortedCartItem.items[i];
                    if (item.UUID === uuid) {
                        $('.js-sorted-product-type-total-quantity').html(sortedCartItem.totalQty);
                        $('.js-sorted-product-type-total-price').html(sortedCartItem.total.formatted);
                    }
                }
            }
        }
    }
}

function updateOptimizeMyRewards() {
    var $optimizeMyRewards = $(SELECTORS.optimizeMyRewards);

    if ($optimizeMyRewards.length) {
        var url = $optimizeMyRewards.data('url');

        $.ajax({
            url: url,
            method: 'GET',
            success: function (html) {
                $optimizeMyRewards.html(html);
            }
        });
    }
}

/**
 * Exports the data to CSV file and automatically downloads it to the user's browser
 * @param {string} filename
 * @param {*} rows
 */
function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

function initTesterReasonForm() {
    const $forms = $(SELECTORS.testerReasonForm);

    const updateReasonFormState = () => {
        const forms = $(SELECTORS.testerReasonForm).toArray();
        const data = forms.reduce((all, form) => {
            const formData = Object.fromEntries(new FormData(form));
            return Object.assign(all, {[$(form).closest('.card').data('uuid')]: formData });
        }, {});

        const url = $('.cart').data('test-reason-update-url');
        $.ajax({
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            url,
            data: (data)
        });
    };

    const updateReasonDebounced = debounce(updateReasonFormState, 600);

    $forms.each((_, element) => {
        const $form = $(element);
        const $select = $form.find('[name="reason"]');
        $form.on('change', () => {
            updateReasonDebounced();
        });
        $select.on('change', (e) => {
            const value = e.target.value;
            const descriptionSection = $form.find('.js-description-section');
            descriptionSection.removeClass('d-none');
            const descriptionLabel = $form.find('.js-description-label');
            descriptionLabel.text(descriptionLabel.data(`label-${value}`));
        });
    });

    const $checkoutBtn = $(SELECTORS.checkoutBtn);

    $checkoutBtn.on('click', e => {
        const allFormsValid = $forms.toArray().map(form => clientValidation.functions.validateForm(form)).every(valid => valid);

        if (!allFormsValid) {
            e.preventDefault();
        }
    });

}

/**
 * Validate Quantity
 * @param {int} qtyInput - current value of the Input Field
 * @param {int} stepQty - Step Quantity for this ProductLineItem
 *
 * @return {int} - fixed quantity
 */
function validateAndFixQuantity(qtyInput, stepQty) {
    if (qtyInput % stepQty !== 0) {
        return Math.ceil(qtyInput / stepQty) * stepQty;
    } else {
        return qtyInput;
    }
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
        updateOptimizeMyRewards();
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('.js-accordion-trigger').click(function (e) {
        e.preventDefault();
        $(e.target).toggleClass(CLASSES.active);
        $(e.target).parent().find('.table').toggleClass('d-none');
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> '
                        + '<div class="col-12 text-center"> '
                        + '<h1>' + data.basket.resources.emptyCartMsg + '</h1> '
                        + '</div> '
                        + '</div>');
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts, data.basket.totals.shippingLevelDiscountTotal.value);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                    updateSortedB2BProducts(data, uuid);
                }
                updateTreeCounter(data.basket);
                $('body').trigger('cart:update', data);
                verifyCheckoutPermissions(data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON && err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', SELECTORS.qtyButtons, function () {
        var $this = $(this);
        var operator = $(this).data('method');
        var $qtyInput = $this.siblings(SELECTORS.qtyInput);
        var qty = parseInt($qtyInput.val());
        var stepQty = parseInt($qtyInput.attr('step'));
        var qtyMin = parseInt($qtyInput.attr('min'));

        if (operator == 'add') {
            qty += stepQty;
            if (qty >= qtyMin + 1) {
                $this.siblings(SELECTORS.minusButton).attr('disabled', false);
            }
        } else {
            qty -= stepQty;
            if (qty <= qtyMin) {
                qty = qtyMin;
                $this.attr('disabled', true);
                if (qty == parseInt($qtyInput.val())) { // Quantity did not change so don't trigger any change
                    return;
                }
            }
        }

        $qtyInput.val(qty);
        $qtyInput.trigger('change');
    });

    $('body').on('change', SELECTORS.qtyInput, function () {
        var $this = $(this);
        var preSelectQty = $(this).data('pre-select-qty');
        var qtyMin = parseInt($this.attr('min'));
        var $minusBtn = $this.parents(SELECTORS.qtySelectorContainer).find(SELECTORS.minusButton);
        var quantity = parseInt($this.val());

        if (isNaN(quantity) || quantity <= qtyMin) {
            $this.val(qtyMin);
            $minusBtn.attr('disabled', true);
        } else {
            $minusBtn.attr('disabled', false);
        }

        // B2B error handling
        quantity = validateAndFixQuantity(quantity, qtyMin);

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();
        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts, data.totals.shippingLevelDiscountTotal.value);
                updateAvailability(data, uuid);
                updateSortedB2BProducts(data, uuid);
                validateBasket(data);
                verifyCheckoutPermissions(data);
                $(this).data('pre-select-qty', quantity);
                updateTreeCounter(data);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
        updateOptimizeMyRewards();
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts, data.totals.shippingLevelDiscountTotal.value);
                    validateBasket(data);
                    verifyCheckoutPermissions(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts, data.totals.shippingLevelDiscountTotal.value);
                    validateBasket(data);
                    verifyCheckoutPermissions(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts, data.totals.shippingLevelDiscountTotal.value);
                validateBasket(data);
                verifyCheckoutPermissions(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts, data.cartModel.totals.shippingLevelDiscountTotal.value);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    updateTreeCounter(data.cartModel);
                    validateBasket(data.cartModel);
                    verifyCheckoutPermissions(data);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('.shipping-charge-form').on('submit', function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.shipping-msg-error').hide();

        if (!$('.shipping-charge-field').val()) {
            $.spinner().stop();
            return false;
        }

        var $form = $('.shipping-charge-form');
        $('.shipping-charge-form .form-control').removeClass('is-invalid');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.shipping-msg-error').empty().append(data.errorMessage);
                } else {
                    updateCartTotals(data);
                    validateBasket(data);
                }

                $.spinner().stop();
            },
            error: function (err) {
                createErrorNotification(err.errorMessage);
                $.spinner().stop();
            }
        });

        return false;
    });

    // Listen for changes to the checkbox
    $(SELECTORS.priorityShipment).change(function() {
        var isChecked = $(this).is(':checked');
        var formAction = $('.priority-shipment-form').attr('action');

        $.ajax({
            url: formAction,
            type: 'GET',
            data: { priorityShipment: isChecked },
            success: function(response) {
                $(SELECTORS.priorityShipmentRes).html(response.msg);
            },
            error: function(error) {
                $(SELECTORS.priorityShipmentRes).html('Error: ' + error);
            }
        });
    });


    $('body').on('click', SELECTORS.promoCodeForm, function (e) {
        $(SELECTORS.promoCodeToggle).addClass(CLASSES.active);
        e.target.classList.add(CLASSES.hidden);
    });

    $('body').on('click', SELECTORS.editGratisNameLink, function (e) {
        e.preventDefault();
        let lineItemUUID = $(this).data('lineitem-uuid');
        let $gratisNameInput = $('.js-gratis-name-input[data-lineitem-uuid="' + lineItemUUID + '"]');
        $gratisNameInput.val($gratisNameInput.val().trim());
        $('.js-gratis-name-wrapper[data-lineitem-uuid="' + lineItemUUID + '"]').removeClass('h-hide');
        $('.js-edit-gratis-name-wrapper[data-lineitem-uuid="' + lineItemUUID + '"]').addClass('h-hide'); // hide the gratis name line
    });

    $('body').on('click', SELECTORS.saveGratisName, function (e) {
        e.preventDefault();
        let lineItemUUID = $(this).data('lineitem-uuid');
        $('.js-gratis-name-wrapper[data-lineitem-uuid="' + lineItemUUID + '"]').removeClass('h-hide');
        let gratisName = $('.js-gratis-name-input[data-lineitem-uuid="' + lineItemUUID + '"]').val().trim();

        var url = $(this).attr('href') + '?lineItemUUID=' + lineItemUUID + '&gratisName=' + gratisName;
        $.ajax({
            url: url,
            method: 'POST',
            success: function (data) {
                if (data.success) {
                    //  TODO: update and hide/show relevant elements through jQuery
                    location.reload();
                }
                location.reload();
            },
            error: function () {
            }
        });
    });

    $('body').on('click', SELECTORS.cancelEditGratisName, function (e) {
        e.preventDefault();
        location.reload(); // TODO: hide/show the relevant elements
    });

    $(SELECTORS.emailNotifyForm).on('submit', e => {
        e.preventDefault();
        const url = e.target.action;

        $.ajax({
            url,
            method: 'POST',
            success: () => alert('Notification successful')
        });
    })

    $('body').on('click', '.clear-cart-confirmation-btn', function (e) {
        e.preventDefault();
        var url = $('.clear-cart').data('action');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                // show the cart page again with a notification
                $.spinner().stop();
                if (data.success){
                    window.location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                createErrorNotification(err.responseJSON.message);
                $.spinner().stop();
            }
        });
    });

    function handlePostCartAdd(response, form) {
        $('.minicart').trigger('count:update', response);
        // show add to cart toast
        var basketPopUp = require('eminence/components/basketPopup');
        basketPopUp(response, form);
    }

    $('body').on('click', 'button.js-add-to-cart-wishlist', function () {
        console.log('here');
        var addToCartUrl;
        var pid;
        var pidsObj;
        pid = $(this).attr('data-pid');

        addToCartUrl = $(this).attr('data-addtocarturl');
        var form = {
            pid: pid,
            pidsObj: pidsObj,
            quantity: '1'
        };

        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data, form);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('click', '.export-cart-btn', function (e) {
        e.preventDefault();
        var url = $('.export-cart-btn').data('action');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                // create a csv file and download it for user
                if (data.success){
                    exportToCsv(data.fileName, JSON.parse(data.csvData))
                }
                $.spinner().stop();
            },
            error: function (err) {
                createErrorNotification(err.responseJSON.message);
                $.spinner().stop();
            }
        });
    });

    // TODO: Technical Debt - Handle maximum Cart update events through Ajax (RPP-1193)
    $('body').on('cart:update', function () {
        let $page = $('.page').data('action');
        if ($page && $page == 'Cart-Show') {
            location.reload();
        }
    });

    // TODO: Technical Debt - Handle maximum Cart update events through Ajax (RPP-1193)
    $('body').on('product:afterAddToCart', function () {
        let $page = $('.page').data('action');
        if ($page && $page == 'Cart-Show') {
            location.reload();
        }
    });

    const fieldCharactersCounter = require('eminence/components/fieldCharactersCounter');
    fieldCharactersCounter.init();

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    base.addWishlistToCart();
    base.addHalfPriceTester();
    showReorderSuccessMessage();
    showOutOfStockMessage();
    base.addSampleGWPProduct();
    base.removeSampleGWPProduct();
    base.addToCart();
    initTesterReasonForm();
}
