'use strict';

require('slick-carousel/slick/slick');

const SELECTORS = {
    homepageSlider: '[data-slider-id="homepage-slider"]',
    slickSlider: '.js-slick-slider',
    progressBar: '.slick-progress',
    stateButton: '.slick-state-button',
    prevBtn: '.slick-prev',
    nextBtn: '.slick-next',
};

const LOCAL_CLASSES = {
    slickInitialized: 'slick-initialized',
};

let isPaused = false;
let progressTimer = null;

$(document).ready(function () {
    const $homepageSlider = $(SELECTORS.homepageSlider);
    const $otherSliders = $(SELECTORS.slickSlider).not($homepageSlider);

    if ($homepageSlider.length) {
        initHomepageSlider($homepageSlider);
    }

    if ($otherSliders.length) {
        initOtherSliders($otherSliders);
    }

    /**
     * Initializes the homepage slider with progress bar functionality
     * @param {jQuery} $slider - The homepage slider element
     */
    function initHomepageSlider($slider) {
        const progressBar = $slider.closest('.c-hero-banner').find(SELECTORS.progressBar);
        const stateButton = $slider.closest('.c-hero-banner').find(SELECTORS.stateButton);

        $slider.slick({
            dots: true,
            prevArrow: $(SELECTORS.prevBtn),
            nextArrow: $(SELECTORS.nextBtn),
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
        });

        $slider.on('beforeChange', function () {
            resetProgressBar(progressBar);
        });

        $slider.on('afterChange', function () {
            if (!isPaused) {
                startProgressBar(progressBar);
            }
        });

        stateButton.on('click', function () {
            togglePlayPause($(this), $slider, progressBar);
        });

        startProgressBar(progressBar);
    }

    /**
     * Initializes other sliders without progress bar functionality
     * @param {jQuery} $sliders - All other slider elements
     */
    function initOtherSliders($sliders) {
        $sliders.each(function () {
            const $slider = $(this);

            if (!$slider.hasClass(LOCAL_CLASSES.slickInitialized)) {
                $slider.slick({
                    dots: true,
                    prevArrow: $(SELECTORS.prevBtn),
                    nextArrow: $(SELECTORS.nextBtn),
                    autoplay: false,
                    infinite: true,
                });

                $slider.addClass(LOCAL_CLASSES.slickInitialized);
            }
        });
    }

    /**
     * Starts the progress bar animation
     * @param {jQuery} $progressBar - The progress bar element
     */
    function startProgressBar($progressBar) {
        resetProgressBar($progressBar);
        const duration = 5000;

        $progressBar.stop().css({ width: '0%' }).animate(
            { width: '100%' },
            duration,
            'linear'
        );

        progressTimer = setTimeout(() => {
            if (!isPaused) {
                $(SELECTORS.homepageSlider).slick('slickNext');
            }
        }, duration);
    }

    /**
     * Resets the progress bar to its initial state
     * @param {jQuery} $progressBar - The progress bar element
     */
    function resetProgressBar($progressBar) {
        $progressBar.stop().css({ width: '0%' });
        clearTimeout(progressTimer);
    }

    /**
     * Toggles play and pause functionality
     * @param {jQuery} $button - The play/pause button
     * @param {jQuery} $slider - The slider element
     * @param {jQuery} $progressBar - The progress bar element
     */
    function togglePlayPause($button, $slider, $progressBar) {
        isPaused = !isPaused;

        if (isPaused) {
            $button.addClass('slick-play-button').removeClass('slick-pause-button');
            $progressBar.stop();
            clearTimeout(progressTimer);
            $slider.slick('slickPause');
        } else {
            $button.addClass('slick-pause-button').removeClass('slick-play-button');
            $slider.slick('slickPlay');
            startProgressBar($progressBar);
        }
    }
});
