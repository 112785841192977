'use strict';

const SELECTORS = {
    body: 'body',
}

const createNotification = require('eminence/components/notification')

/**
 * @description Initializes the global notifications functionality
 */
function init() {
    if (!window.GlobalNotifications) { // eslint-disable-line
        return;
    }

    for (const property in GlobalNotifications) { // eslint-disable-line
        if (!GlobalNotifications[property]) { // eslint-disable-line
            continue;
        }

        let jsClass = `js-${property.replaceAll('_', '-')}`
        let $notification = $(`<div class="global-notifiction ${jsClass}">`);

        $(jsClass).remove();
        $(SELECTORS.body).append($notification);

        createNotification($(`.${jsClass}`), GlobalNotifications[property], 'success', 5000); // eslint-disable-line
    }
}

module.exports = init();
